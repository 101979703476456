import axios from 'axios'
import store from './store'
import router from './router'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND
})

// Add a request interceptor
instance.interceptors.request.use(
    config => {
        store.state.loading = true

        const token = localStorage.getItem('jwt')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    error => {
        Promise.reject(error)
    }
)

//  Add a response interceptor
instance.interceptors.response.use(
    response => {
        store.dispatch('setLoading', false)

        if (response && response.data.status === 'error') {
            store.dispatch('setErrorModalShow', true)
            store.dispatch('setErrorModalMsg', response.data.msg)
        }

        return response
    }, function (error) {
        if (error.response.status === 401) {
            router.push({ name: 'Login' }, () => {})
            store.dispatch('setErrorModalShow', true)
            store.dispatch('setErrorModalMsg', error.response.data)
            store.dispatch('setLoading', false)

            localStorage.setItem('jwt', null)
            localStorage.setItem('username', null)
        } else if (error.response.status === 403) {
            localStorage.setItem('jwt', null)
            localStorage.setItem('username', null)
            router.push({ name: 'Login' }, () => {})
            store.dispatch('setLoading', false)
        }
    })

export default instance
