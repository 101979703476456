import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/liff/booking',
        component: () => import('../liffs/Booking.vue')
    },
    {
        path: '/mobile/students',
        component: () => import('../views/StudentsMobile.vue')
    },
    {
        path: '/',
        component: () => import('../views/MainLayout.vue'),
        children: [
            { path: '/branches', component: () => import('../views/Branch.vue') },
            { path: '/ep-courses', component: () => import('../views/Course.vue') },
            { path: '/group-courses', component: () => import('../views/GroupCourse.vue') },
            { path: '/last-time-booking', component: () => import('../views/ConfigBooking.vue') },
            { path: '/students', component: () => import('../views/Students.vue') },
            { path: '/students/create', component: () => import('../views/StudentForm.vue') },
            { path: '/students/edit/:id', component: () => import('../views/StudentForm.vue') },
            { path: '/students/:id/courses', component: () => import('../views/StudentCourses.vue') },
            { path: '/ep-classes', component: () => import('../views/EpClass.vue') },
            { path: '/ep-classes/:id/student-booking', component: () => import('../views/StudentBookingList.vue') },
            { path: '/ep-classes/:id/student-booking/status', component: () => import('../views/UpdateStudentAttendance.vue') },
            { path: '/staff', component: () => import('../views/Staff.vue') },
            { path: '/booking', component: () => import('../liffs/Booking.vue') }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
