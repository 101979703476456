<template>
    <v-app>
        <v-main>
            <loading-component v-show="this.$store.state.loading" />
            <router-view/>
            <notifications position="bottom right"/>
        </v-main>
    </v-app>
</template>

<script>
import LoadingComponent from './components/LoadingPage'

export default {
    name: 'App',
    components: {
        LoadingComponent
    },

    data: () => ({
    })
}
</script>
<style lang="scss">
@import "@/assets/global.scss";
</style>
