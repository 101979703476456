import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        errorModalShow: false,
        errorModalMsg: '',
        successModalShow: false,
        successModalMsg: ''
    },
    mutations: {
        setLoading (state, payload) {
            state.loading = payload
        },

        setErrorModalShow (state, payload) {
            state.errorModalShow = payload
        },
        setErrorModalMsg (state, payload) {
            state.errorModalMsg = payload
        },
        setSuccessModalShow (state, payload) {
            state.successModalShow = payload
        },
        setSuccessModalMsg (state, payload) {
            state.successModalMsg = payload
        }
    },
    actions: {
        setLoading ({ commit }, payload) {
            commit('setLoading', payload)
        },
        setErrorModalShow ({ commit }, payload) {
            commit('setErrorModalShow', payload)
        },
        setErrorModalMsg ({ commit }, payload) {
            commit('setErrorModalMsg', payload)
        },
        setIsCameraSerivceRunning ({ commit }, payload) {
            commit('setIsCameraSerivceRunning', payload)
        },
        setSuccessModalShow ({ commit }, payload) {
            commit('setSuccessModalShow', payload)
        },
        setSuccessModalMsg ({ commit }, payload) {
            commit('setSuccessModalMsg', payload)
        }
    },
    modules: {
    }
})
